import { ErrorSeverityEnum } from '../enums/error-severity.enum'
import { HttpStatusCodesEnum } from '../enums/http-status-codes.enum'
import { HttpResponseDataErrorInterface } from '../interfaces/http-response/http-response-data-error.interface'

export class BaseError extends Error {
  severity: ErrorSeverityEnum = ErrorSeverityEnum.Error
  context?: HttpResponseDataErrorInterface
  status = HttpStatusCodesEnum.BAD_REQUEST

  constructor(error: Error) {
    super(error.message)
    this.message = error.message
  }
}
