import { ConfigLoadingError } from '../errors/config-loading.error'
import { AppConfigInterface } from '../interfaces/app-config.interface'

export class ConfigLoaderService {
  private static config: Record<string, AppConfigInterface> = {}
  static getConfig = (id: string): AppConfigInterface => {
    if (this.config[id]) {
      return this.config[id]
    }

    const element = document.getElementById(id)
    if (element) {
      try {
        this.config[id] = JSON.parse(element.innerHTML)

        return this.config[id]
      } catch (error) {
        throw new Error(
          'Can not parse the data from Element "' +
            id +
            '" for configuration.\n' +
            String(error)
        )
      }
    }

    throw new ConfigLoadingError(
      'Can not find the Element "' + id + '" for configuration.'
    )
  }
}
