import { Controller } from '@hotwired/stimulus'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { DatePickerViewModeEnum } from '../enums/date-picker-view-mode.enum'
import { button, htmlBadge, inputDateTime } from '../helpers/html.helper'
import { getUrl } from '../helpers/url.helper'
import { RosterEditInterface } from '../interfaces/roster-edit.interface'
import { ConfigLoaderService } from '../services/config-loader.service'
import { HttpService } from '../services/http.service'
import { LockService } from '../services/lock.service'
import { TableService } from '../services/table.service'
import { ToastService } from '../services/toast.service'

dayjs.extend(customParseFormat)

export default class extends Controller<HTMLFormElement> {
  static targets = ['user', 'datepicker', 'table']
  static values = {
    urlGet: String,
    urlUpdate: String,
  }
  private tableTarget: HTMLTableElement
  private urlGetValue: string
  private urlUpdateValue: string
  private userTarget?: HTMLSelectElement
  private datepickerTarget: HTMLInputElement

  load = async () => {
    const id = this.userTarget.value

    if (!Number.isInteger(Number(id)) || Number(id) < 1) {
      // todo translate
      ToastService.showMessage(
        'Der ausgewählte Mitarbeiter ist ungültig!',
        'Es ist ein Fehler aufgetreten'
      )
      return
    }

    const data = await HttpService.get(
      getUrl(this.urlGetValue, [
        { key: 'id', value: Number(id) },
        { key: 'date', value: this.datepickerTarget.value },
      ])
    )

    const employeeName = Array.from(
      this.userTarget.selectedOptions,
      option => option.text
    ).shift()

    TableService.removeAllRows(this.tableTarget)
    TableService.addRows(this.tableTarget, this.getRows(data.rosters))

    const cardModule = this.tableTarget.closest('.card-module')

    cardModule.classList.remove('d-none')
    cardModule.querySelector('h2').innerText = employeeName
  }

  edit = async (event: MouseEvent) => {
    const buttonEdit = event.currentTarget as HTMLButtonElement
    const buttonSave = buttonEdit.nextSibling as HTMLButtonElement

    buttonEdit.classList.add('d-none')
    buttonSave.classList.remove('d-none')

    //switch to edit mode
    const currentRow = buttonEdit.closest('tr')
    const startDate = currentRow.querySelector('td:nth-child(2)')
    const endDate = currentRow.querySelector('td:nth-child(3)')

    const userData = ConfigLoaderService.getConfig('user-data')

    startDate.innerHTML = inputDateTime(
      startDate.innerHTML,
      'dd.MM.yyyy HH:mm',
      DatePickerViewModeEnum.CLOCK,
      userData.language
    )
    endDate.innerHTML = inputDateTime(
      endDate.innerHTML,
      'dd.MM.yyyy HH:mm',
      DatePickerViewModeEnum.CLOCK,
      userData.language
    )
  }

  update = async (event: MouseEvent) => {
    const btnSave = event.currentTarget as HTMLButtonElement

    try {
      if (LockService.isLocked(btnSave)) {
        return
      }

      LockService.lock(btnSave)

      ToastService.hide()

      const rosterId = btnSave.value

      const currentRow = btnSave.closest('tr')

      const startDateInput = currentRow.querySelector(
        'td:nth-child(2) input'
      ) as HTMLInputElement

      const endDateInput = currentRow.querySelector(
        'td:nth-child(3) input'
      ) as HTMLInputElement

      await HttpService.put(getUrl(this.urlUpdateValue), {
        id: Number(rosterId),
        start: startDateInput.value,
        end: endDateInput.value,
      })

      const btnEdit = btnSave.previousSibling as HTMLButtonElement

      btnEdit.classList.remove('d-none')
      btnSave.classList.add('d-none')

      const startDate = currentRow.querySelector('td:nth-child(2)')
      const endDate = currentRow.querySelector('td:nth-child(3)')

      startDate.innerHTML = startDate.querySelector('input').value
      endDate.innerHTML = endDate.querySelector('input').value
    } catch (error) {
      ToastService.showError(error)
    } finally {
      LockService.unlock(btnSave)
    }
  }

  getRows = (data: RosterEditInterface[]) => {
    return data.map(roster => {
      let index = 0
      return {
        active: true,
        columns: [
          {
            value: roster.client.name,
            index: index++,
          },
          {
            value: roster.start,
            index: index++,
          },
          {
            value: roster.end,
            index: index++,
          },
          {
            value: roster.absence
              ? htmlBadge(
                  roster.absence.type.shortcut,
                  '',
                  roster.absence.type.bgColor
                )
              : button(
                  'click->roster-edit#edit',
                  'btn btn-warning',
                  'bi bi-pencil-fill',
                  String(roster.id)
                ) +
                button(
                  'click->roster-edit#update',
                  'btn btn-success d-none',
                  'bi bi-floppy',
                  String(roster.id)
                ),
            index: index,
          },
        ],
      }
    })
  }
}
