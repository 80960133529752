import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import {
  Calendar,
  CalendarApi,
  DateSelectArg,
  EventClickArg,
  EventDropArg,
  ViewMountArg,
} from '@fullcalendar/core'
import { EventImpl } from '@fullcalendar/core/internal'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import dayjs from 'dayjs'
import SlimSelect from 'slim-select'
import { Option } from 'slim-select/src/slim-select/store'

import { urls } from '../config/config'
import { CalenderEventDisplayEnum } from '../enums/calendar/calender-event-display.enum'
import { CalenderViewTypesEnum } from '../enums/calendar/calender-view-types.enum'
import { FetchingError } from '../errors/calendar/fetching.error'
import { resetForm } from '../helpers/form.helper'
import { getUrl } from '../helpers/url.helper'
import { AbsenceCalendarParamsInterface } from '../interfaces/absence-calendar-params.interface'
import { HttpService } from './http.service'
import { Logger } from './logger.service'
import { OffCanvasService } from './off-canvas.service'
import { ToastService } from './toast.service'

export class AbsenceCalendarService {
  private static currentView = CalenderViewTypesEnum.DAY_GRID_MONTH
  private static absenceTypeSelect: SlimSelect
  private static calendarInstance: Calendar
  static getCalendar = (params: AbsenceCalendarParamsInterface) => {
    const { eventsUrl, calendarEl, language } = params

    const calendarWrapper = calendarEl.closest('.absence-calendar')

    const datepickerStartTarget = calendarWrapper.querySelector(
      '[name="absence_form[beginAt]"]'
    ) as HTMLInputElement

    const datepickerEndTarget = calendarWrapper.querySelector(
      '[name="absence_form[endAt]"]'
    ) as HTMLInputElement

    const absenceTypeTarget = calendarWrapper.querySelector(
      '[name="absence_form[type]"]'
    ) as HTMLSelectElement

    const absenceGroupIdTarget = calendarWrapper.querySelector(
      '[name="absence_group_id"]'
    ) as HTMLInputElement

    const formTarget = calendarWrapper.querySelector(
      '[name="absence"]'
    ) as HTMLFormElement

    this.calendarInstance = new Calendar(calendarEl, {
      displayEventTime: false, // don't show the time column in list view
      editable: true,
      eventDisplay: CalenderEventDisplayEnum.BLOCK,
      firstDay: 1,
      initialView: CalenderViewTypesEnum.DAY_GRID_MONTH,
      locale: language,
      navLinks: true, // can click day/week names to navigate views
      plugins: [interactionPlugin, dayGridPlugin, listPlugin, bootstrap5Plugin],
      selectable: true,
      selectOverlap: function (event: EventImpl) {
        return event.extendedProps.event_type !== 'absence'
      },
      themeSystem: 'bootstrap5',
      timeZone: 'CET',
      eventSources: [
        {
          id: 'absences',
          url: eventsUrl,
          method: 'POST',
          extraParams: {
            filters: JSON.stringify({}), // pass your parameters to the subscriber
          },
          failure: error => {
            Logger.logException(new FetchingError(error))
          },
        },
      ],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: '',
      },
      eventClick: (info: EventClickArg) => {
        resetForm(formTarget)

        this.absenceTypeSelect.setSelected(
          String(info.event.extendedProps.absence_type_id)
        )

        datepickerStartTarget.value = info.event.startStr.split('T')[0]
        datepickerEndTarget.value = info.event.endStr.split('T')[0]
        absenceGroupIdTarget.value = info.event.groupId

        OffCanvasService.show()
      },
      eventDrop: async (arg: EventDropArg) => {
        resetForm(formTarget)

        this.absenceTypeSelect.setSelected(
          String(arg.event.extendedProps.absence_type_id)
        )
        datepickerStartTarget.value = arg.event.startStr.split('T')[0]
        datepickerEndTarget.value = arg.event.endStr.split('T')[0]

        const formData = new FormData(formTarget)
        const groupId = arg.event.groupId
        const url = getUrl(`${urls.absence_api}/${groupId}`)

        try {
          await HttpService.post(url, formData)
        } catch (error) {
          ToastService.showError(error)
        }
      },
      select: (info: DateSelectArg) => {
        // reset fields
        resetForm(formTarget)

        datepickerStartTarget.value = info.startStr
        datepickerEndTarget.value = dayjs(info.endStr)
          .subtract(1, 'second')
          .format('YYYY-MM-DD')

        OffCanvasService.show()
      },
      viewDidMount: (arg: ViewMountArg) => {
        this.addDomElements(calendarEl, formTarget)
        this.initViewSelect(arg.view.calendar)
        this.absenceTypeSelect = this.initAbsenceTypeSelect(absenceTypeTarget)
      },
    })
  }

  public static addAbsenceCreatedListener = () => {
    document.addEventListener('absenceCreatedEvent', () => {
      this.calendarInstance.getEventSourceById('absences').refetch()
    })
  }

  public static render = () => {
    this.calendarInstance.render()
  }

  private static addDomElements = (
    calendarEl: HTMLElement,
    formTarget: HTMLFormElement
  ) => {
    const viewContainer = calendarEl.querySelectorAll('.fc-toolbar-chunk')[2]

    viewContainer.innerHTML = ''

    const flexContainer = document.createElement('div')
    flexContainer.classList.add('d-flex', 'align-items-center')

    // add view select
    const selectBox = document.createElement('select')
    selectBox.classList.add('select-box', 'form-select')

    // add add absence button
    const button = document.createElement('button')
    button.classList.add('btn', 'btn-primary', 'text-nowrap', 'ms-3')
    button.innerText = 'Abwesenheit beantragen' // todo translate
    button.addEventListener('click', function () {
      resetForm(formTarget)
      OffCanvasService.show()
    })

    flexContainer.append(selectBox, button)
    viewContainer.append(flexContainer)
  }

  private static initAbsenceTypeSelect = (
    absenceTypeTarget: HTMLSelectElement
  ) => {
    return new SlimSelect({
      select: absenceTypeTarget,
      settings: {
        showSearch: false,
      },
    })
  }

  private static initViewSelect = (calendar: CalendarApi) => {
    return new SlimSelect({
      select: '#calendar-holder .select-box',
      settings: {
        showSearch: false,
      },
      // Options
      data: [
        {
          text: 'Monatsansicht', // todo translate
          value: CalenderViewTypesEnum.DAY_GRID_MONTH,
          selected: this.currentView === CalenderViewTypesEnum.DAY_GRID_MONTH,
        },
        {
          text: 'Listenansicht', // todo translate
          value: CalenderViewTypesEnum.LIST_YEAR,
          selected: this.currentView === CalenderViewTypesEnum.LIST_YEAR,
        },
      ],
      events: {
        beforeChange: (value: Option[]) => {
          this.currentView = value[0].value as CalenderViewTypesEnum
          calendar.changeView(this.currentView)
        },
      },
    })
  }
}
