export class AbsenceCreatedEvent extends CustomEvent<unknown> {
  constructor() {
    super('absenceCreatedEvent', {
      detail: {},
      bubbles: true,
      cancelable: true,
      composed: false,
    })
  }
}
