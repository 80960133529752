import '@eonasdan/tempus-dominus/src/scss/tempus-dominus.scss'

import { DateTime, TempusDominus } from '@eonasdan/tempus-dominus'

import { DatePickerViewModeEnum } from '../enums/date-picker-view-mode.enum'
import { LanguagesEnum } from '../enums/languages.enum'

export class DatePickerService {
  private static readonly defaultFormat: 'yyyy-MM'

  public static init = (
    element: HTMLElement,
    viewMode: DatePickerViewModeEnum,
    dateFormat?: string,
    locale?: LanguagesEnum,
    viewDate?: number
  ) => {
    const selectedDate = viewDate ? viewDate * 1000 : 'now'

    return new TempusDominus(element, {
      allowInputToggle: true,
      display: {
        viewMode,
        components: DatePickerService.components(viewMode),
        icons: DatePickerService.icons(),
        theme: 'light',
        sideBySide: viewMode === DatePickerViewModeEnum.CLOCK,
      },
      localization: {
        locale: locale ?? LanguagesEnum.ENGLISH,
        format: dateFormat || this.defaultFormat,
        startOfTheWeek: 1,
      },
      useCurrent: selectedDate === null,
      viewDate: new DateTime(selectedDate),
    })
  }

  private static components = (viewMode: DatePickerViewModeEnum) => {
    return {
      date: viewMode !== DatePickerViewModeEnum.MONTHS,
      month: true,
      year: true,
      decades: true,
      clock: DatePickerViewModeEnum.CLOCK === viewMode,
      hours: DatePickerViewModeEnum.CLOCK === viewMode,
      minutes: DatePickerViewModeEnum.CLOCK === viewMode,
      seconds: false,
    }
  }

  private static icons = () => {
    return {
      time: 'bi bi-clock',
      date: 'bi bi-calendar',
      up: 'bi bi-arrow-up',
      down: 'bi bi-arrow-down',
      previous: 'bi bi-chevron-left',
      next: 'bi bi-chevron-right',
      today: 'bi bi-calendar-check',
      clear: 'bi bi-trash',
      close: 'bi bi-x',
    }
  }
}
