import { AxiosError, AxiosResponse } from 'axios'

import { HttpStatusCodesEnum } from '../enums/http-status-codes.enum'
import { HttpResponseDataErrorInterface } from '../interfaces/http-response/http-response-data-error.interface'
import { BaseError } from './base.error'

export class HttpAxiosError extends BaseError {
  constructor(error: AxiosError) {
    super(error)
    this.status = this.getStatus(error)
    this.message = this.getMessage(error)
    this.context = this.getContext(error)
    this.name = 'HttpAxiosError'
  }

  getResponse(error: AxiosError): AxiosResponse {
    const { response } = error
    return response
  }

  getContext(error: AxiosError): HttpResponseDataErrorInterface {
    const response = this.getResponse(error)
    if (!response) {
      return {
        code: error.code,
        message: error.message,
        title: 'error',
      }
    }
    return response.data as HttpResponseDataErrorInterface
  }

  getStatus(error: AxiosError): HttpStatusCodesEnum {
    const response = this.getResponse(error)

    if (!response) {
      return HttpStatusCodesEnum.BAD_REQUEST
    }

    return (
      (response?.status as HttpStatusCodesEnum) ||
      HttpStatusCodesEnum.BAD_REQUEST
    )
  }

  getMessage(error: AxiosError): string {
    const { message } = this.getContext(error)
    return message || error.message
  }
}
