import axios, { AxiosError } from 'axios'
import axiosRetry, {
  exponentialDelay,
  isNetworkOrIdempotentRequestError,
} from 'axios-retry'

import { HttpStatusCodesEnum } from '../enums/http-status-codes.enum'
import { HttpAxiosError } from '../errors/axios.error'
import { HttpResponseRedirectInterface } from '../interfaces/http-response/http-response-redirect.interface'

export class HttpService {
  static post = async (url: URL, params: unknown) => {
    const { data } = await this.getClient().post(url.href, params)
    return data
  }

  static put = async (url: URL, params?: unknown) => {
    const { data } = await this.getClient().put(url.href, params)
    return data
  }

  static get = async (url: URL, params?: unknown) => {
    const { data } = await this.getClient().get(url.href, { params })
    return data
  }

  static delete = async (url: URL) => {
    const { data } = await this.getClient().delete(url.href)
    return data
  }

  private static getClient() {
    const client = axios.create()

    axiosRetry(client, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: e => {
        return (
          isNetworkOrIdempotentRequestError(e) || e?.code === 'ECONNABORTED'
        )
      },
      retryDelay: exponentialDelay,
    })

    // client.interceptors.request.use(config => {
    //   config.timeout = 5000 // Wait for 5 seconds before timing out
    //   return config
    // })

    client.interceptors.response.use(
      response => response,
      this.responseErrorHandler
    )

    return client
  }

  private static responseErrorHandler(error: AxiosError) {
    if (error?.response?.status === HttpStatusCodesEnum.UNAUTHORIZED) {
      const { redirectTo } = error.response
        .data as HttpResponseRedirectInterface
      // redirect user
      window.location.href = redirectTo
    }

    return Promise.reject(new HttpAxiosError(error))
  }
}
