import * as Sentry from '@sentry/browser'

import { HttpStatusCodesEnum } from '../enums/http-status-codes.enum'
import { BaseError } from '../errors/base.error'
import { isLocal } from '../helpers/is-local.helper'
import { ConfigLoaderService } from './config-loader.service'

export class Logger {
  static logException(error: Error) {
    const userData = ConfigLoaderService.getConfig('user-data')

    // eslint-disable-next-line no-console
    console.error(error)
    if (
      isLocal() ||
      (error instanceof BaseError &&
        error.status === HttpStatusCodesEnum.UNAUTHORIZED)
    ) {
      return
    } else if (error instanceof BaseError) {
      Sentry.withScope(scope => {
        scope.setLevel(error.severity)
        scope.setContext('data', { ...error.context })
        scope.setContext('userData', { ...userData })
        Sentry.captureException(error)
      })
    } else {
      Sentry.withScope(scope => {
        scope.setContext('userData', { ...userData })
        Sentry.captureException(error)
      })
    }
  }
}
