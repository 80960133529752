import { AlertTypesEnum } from '../enums/alert-types.enum'
import { RosterResponseCodeEnum } from '../enums/roster-response-code.enum'
import { getUrl } from '../helpers/url.helper'
import { HttpResponseDataShiftsInterface } from '../interfaces/http-response/http-response-data-shifts.interface'
import { RosterGetRequestParamsInterface } from '../interfaces/roster-get-request-params.interface'
import { AlertService } from './alert.service'
import { HttpService } from './http.service'
import { Logger } from './logger.service'
import { SpinnerService } from './spinner.service'
import { ToastService } from './toast.service'

export class RosterService {
  static createDomElements(
    data: HttpResponseDataShiftsInterface,
    id = 0
  ): HTMLDivElement {
    const { calendarHtml, sidebarHtml } = data

    const grid = document.createElement('div')
    grid.classList.add('d-grid', 'time-line-calendar-grid')
    grid.dataset.roster = `${id}`

    const sidebar = document.createElement('div')
    sidebar.classList.add('time-line-calendar-sidebar')
    sidebar.innerHTML = sidebarHtml

    const calendar = document.createElement('div')
    calendar.classList.add('time-line-calendar-content')
    calendar.innerHTML = calendarHtml

    grid.append(sidebar, calendar)

    return grid
  }

  static clear(clientIds: string[], target: HTMLDivElement): void {
    const existing = Array.from(target.querySelectorAll('[data-roster]')).map(
      (item: HTMLDivElement) => item.dataset.roster
    )
    const diff = existing.filter(id => !clientIds.includes(id))

    diff.forEach(id => target.querySelector(`[data-roster="${id}"]`)?.remove())
  }

  static async getShifts(
    rostersTarget: HTMLDivElement,
    url: string,
    date: string,
    id?: number
  ): Promise<void> {
    try {
      ToastService.hide()

      const data: HttpResponseDataShiftsInterface = await HttpService.get(
        getUrl(url),
        this.getQueryParams(date, id)
      )

      if (data.code === RosterResponseCodeEnum.NO_SHIFTS) {
        AlertService.show(AlertTypesEnum.INFO, rostersTarget, data.message)
        return
      }

      const roster = RosterService.createDomElements(data, id)
      rostersTarget.appendChild(roster)
      SpinnerService.hide(rostersTarget)
    } catch (error) {
      ToastService.showError(error)
      Logger.logException(error)
    }
  }

  static getQueryParams(
    date: string,
    id?: number
  ): RosterGetRequestParamsInterface {
    const params: RosterGetRequestParamsInterface = { date }

    if (id) {
      params.id = id
    }

    return params
  }
}
