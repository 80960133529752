export const getUrl = (
  uri: string,
  params?: { key: string; value: string | number | boolean }[]
): URL => {
  const url = new URL(uri, process.env.DOMAIN)

  if (params) {
    for (const { key, value } of params) {
      url.searchParams.append(key.toString(), value.toString())
    }
  }

  return url
}
