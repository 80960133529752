import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'

/* stimulusFetch: 'lazy' */
export default class extends Controller<HTMLFormElement> {
  async connect() {
    const selects = document.querySelectorAll('.select-box')

    Array.from(selects).forEach((select: HTMLSelectElement) => {
      new SlimSelect({
        select,
        settings: {
          showSearch: select.dataset.showSearch !== 'no',
          closeOnSelect: !select.multiple,
          hideSelected: true,
          searchPlaceholder: 'Suchen',
          placeholderText: select.getAttribute('data-placeholder'),
          openPosition: 'down', // 'auto', 'up' or 'down'
          contentPosition: 'absolute', // 'absolute' or 'relative'
        },
      })
    })
  }
}
