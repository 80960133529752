import {
  countryFlag,
  emailLink,
  htmlBadge,
  htmlLink,
} from '../helpers/html.helper'
import { phoneLink } from '../helpers/phone-link.helper'
import { UserInterface } from '../interfaces/user.interface'
import { TableContentController } from './table-content-controller'

export default class extends TableContentController {
  getRows = (data: UserInterface[]) => {
    return data.map(user => {
      let index = 0
      return {
        active: user.active,
        columns: [
          {
            value:
              htmlLink(
                `/users/${user.id}/edit`,
                `${user.userData.lastname}, ${user.userData.firstname}`
              ) + htmlBadge(user.userData.ein),
            index: index++,
          },
          {
            value: emailLink(user.email),
            index: index++,
          },
          {
            value: user.userData.address?.street ?? '',
            index: index++,
          },
          {
            value: user.userData.address?.houseNumber ?? '',
            index: index++,
          },
          {
            value: user.userData.address?.city ?? '',
            index: index++,
          },
          {
            value: user.userData.address?.zipcode ?? '',
            index: index++,
          },
          {
            value: countryFlag(user.userData.address?.country),
            index: index++,
          },
          {
            value: phoneLink(user.userData?.mobile),
            index: index++,
          },
          {
            value: phoneLink(user.userData?.phone),
            index: index++,
          },
          {
            value: user.userData?.birthday ?? '',
            index: index,
          },
        ],
      }
    })
  }
}
