import { TableContentInterface } from '../interfaces/table-content.interface'

export class TableService {
  static removeAllRows = (tableRef: HTMLTableElement) => {
    Array.from(tableRef.querySelectorAll('tbody tr')).forEach(row => {
      row.remove()
    })
  }

  static addRows = (
    tableRef: HTMLTableElement,
    rows: TableContentInterface[]
  ) => {
    rows.forEach(({ active, columns }) => {
      // Insert a row at the end of the table
      const newRow = tableRef.querySelector('tbody').insertRow(-1)
      newRow.setAttribute('data-active', active ? '1' : '0')
      columns.forEach(({ value, index }) => {
        this.insertCell(newRow, value, index)
      })
    })
  }

  static insertCell = (
    row: HTMLTableRowElement,
    html: string,
    index: number
  ) => {
    const newCell = row.insertCell(index)
    newCell.innerHTML = html
  }
}
