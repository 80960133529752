import { countryFlag, htmlLink } from '../helpers/html.helper'
import { phoneLink } from '../helpers/phone-link.helper'
import { ClientInterface } from '../interfaces/client.interface'
import { TableContentController } from './table-content-controller'

export default class extends TableContentController {
  getRows = (data: ClientInterface[]) => {
    return data.map(client => {
      let index = 0
      return {
        active: client.active,
        columns: [
          {
            value: htmlLink(`/clients/${client.id}/edit`, client.name),
            index: index++,
          },
          {
            value: client.shortcut,
            index: index++,
          },
          {
            value: client.contactPerson,
            index: index++,
          },
          {
            value: client.address?.street ?? '',
            index: index++,
          },
          {
            value: client.address?.houseNumber ?? '',
            index: index++,
          },
          {
            value: client.address?.city ?? '',
            index: index++,
          },
          {
            value: client.address?.zipcode ?? '',
            index: index++,
          },
          {
            value: countryFlag(client.address?.country),
            index: index++,
          },
          {
            value: phoneLink(client?.mobile),
            index: index++,
          },
          {
            value: phoneLink(client?.phone),
            index: index,
          },
        ],
      }
    })
  }
}
