import { AlertTypesEnum } from '../enums/alert-types.enum'
import { DragEndEvent } from '../events/drag-end.event'
import { getUrl } from '../helpers/url.helper'
import { HttpResponseDataShiftsInterface } from '../interfaces/http-response/http-response-data-shifts.interface'
import { HttpService } from './http.service'
import { Logger } from './logger.service'
import { OffCanvasService } from './off-canvas.service'
import { SpinnerService } from './spinner.service'
import { ToastService } from './toast.service'

export class AbsenceCalendarTimelineService {
  public static async getHtml(url: URL) {
    const { calendarHtml, sidebarHtml }: HttpResponseDataShiftsInterface =
      await HttpService.get(url)

    return this.createDomElements(calendarHtml, sidebarHtml)
  }

  static render = async (
    timelineCalendarTarget: HTMLDivElement,
    datePickerElement: HTMLInputElement,
    uri: string
  ) => {
    try {
      SpinnerService.show(timelineCalendarTarget)
      const url = getUrl(uri, [{ key: 'date', value: datePickerElement.value }])
      const html = await AbsenceCalendarTimelineService.getHtml(url)

      timelineCalendarTarget.appendChild(html)
      SpinnerService.hide(timelineCalendarTarget)
    } catch (error) {
      Logger.logException(error)
      ToastService.showError(error)
    }
  }

  static handleDrag = (dragEventStart: PointerEvent) => {
    const target = dragEventStart.currentTarget as HTMLElement
    target.addEventListener(
      'dragEndEvent',
      (event: DragEndEvent) => {
        const { dragStartElement, dragEndElement } = event.detail

        if (
          !dragStartElement.classList.contains(
            'time-line-calendar-content-day'
          ) ||
          !dragEndElement.classList.contains('time-line-calendar-content-day')
        ) {
          ToastService.showMessage(
            'Ungültige Auswahl', // todo translate
            'Ungültige Auswahl', // todo translate
            AlertTypesEnum.DANGER
          )
          return
        }

        const start = dragStartElement.dataset.date
        const end = dragEndElement.dataset.date
        const userId = dragEndElement.dataset.userid

        const offCanvasElement = OffCanvasService.show()

        const datePickerStart = offCanvasElement.querySelector(
          '[name="absence_form[beginAt]"]'
        ) as HTMLInputElement

        const datePickerEnd = offCanvasElement.querySelector(
          '[name="absence_form[endAt]"]'
        ) as HTMLInputElement

        const userIdInput = offCanvasElement.querySelector(
          '[name="absence_user_id"]'
        ) as HTMLInputElement

        datePickerStart.value = start
        datePickerEnd.value = end
        userIdInput.value = userId
      },
      { once: true }
    )
  }

  public static addAbsenceCreatedListener = (
    timelineCalendarTarget: HTMLDivElement,
    datePickerElement: HTMLInputElement,
    uri: string
  ) => {
    document.addEventListener('absenceCreatedEvent', async () => {
      await this.render(timelineCalendarTarget, datePickerElement, uri)
    })
  }

  private static createDomElements = (
    calendarHtml: string,
    sidebarHtml: string
  ) => {
    const grid = document.createElement('div')
    grid.classList.add('d-grid', 'time-line-calendar-grid')

    const sidebar = document.createElement('div')
    sidebar.classList.add('time-line-calendar-sidebar')
    sidebar.innerHTML = sidebarHtml

    const calendar = document.createElement('div')
    calendar.classList.add('time-line-calendar-content')
    calendar.innerHTML = calendarHtml

    grid.append(sidebar, calendar)

    return grid
  }
}
