import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum'
import { DragEndEvent } from '../events/drag-end.event'
import { RosterSelectedDatesInterface } from '../interfaces/roster-selected-dates.interface'
import { LocalStorageService } from './local-storage.service'
import { ModalService } from './modal.service'

export class RosterShiftService {
  static calculateTotalShiftsPerDayForMonth(clientId: string) {
    const listOfDays: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      `[data-roster="${clientId}"] [data-roster-shifts-date-total]`
    )

    for (const day of listOfDays) {
      const date = day.getAttribute('data-roster-shifts-date-total')
      this.calculateTotalShiftsPerDay(clientId, date, day)
    }
  }

  static calculateTotalShiftsPerDay(
    clientId: string,
    date: string,
    dayElement: HTMLDivElement
  ) {
    const numberOfShifts = document.querySelectorAll(
      `[data-roster="${clientId}"] [data-user-shift$="${date}"]:not([data-user-absenceid])`
    ).length

    dayElement.querySelector('span').innerHTML = numberOfShifts.toString()
  }

  static getShiftDiv(
    shiftId: string,
    clientId: string,
    userId: string,
    date: string
  ): HTMLDivElement | undefined {
    return document.querySelector(
      this.shiftSelector(shiftId, clientId, userId, date)
    )
  }

  static removeShiftsFromCalendar(
    clientId: string,
    userId: string,
    date: string
  ) {
    const items: NodeListOf<HTMLDivElement> = document.querySelectorAll(
      this.shiftSelector('', clientId, userId, date, '$')
    )
    items?.forEach(item => item.remove())
  }

  static availableShifts(
    clientId: string,
    onlySelected = true
  ): Array<HTMLInputElement> {
    const modalElement = ModalService.getHtmlElement(clientId)
    if (!modalElement) {
      return []
    }

    const state = onlySelected ? ':checked' : ''

    return Array.from(
      modalElement.querySelectorAll(`.form-check-input${state}`)
    )
  }

  static preSelectChosenShifts(clientId: string, userId: string, date: string) {
    this.availableShifts(clientId, false).forEach((item: HTMLInputElement) => {
      item.checked =
        this.getShiftDiv(item.value, clientId, userId, date) !== null
    })
  }

  static resetModal(clientId: string) {
    this.availableShifts(clientId, false).forEach((item: HTMLInputElement) => {
      item.checked = false
    })
  }

  static handleDrag = (dragEventStart: PointerEvent) => {
    const target = dragEventStart.currentTarget as HTMLElement
    target.addEventListener(
      'dragEndEvent',
      (event: DragEndEvent) => {
        const { dragStartElement, dragEndElement } = event.detail
        const userIdValue = dragEndElement.dataset.rosterShiftsUserIdValue
        const clientIdValue = dragEndElement.dataset.rosterShiftsClientIdValue
        const dateValue = dragEndElement.dataset.rosterShiftsDateValue

        const date: RosterSelectedDatesInterface = {
          start: {
            formatted: dragStartElement.dataset.rosterShiftsDateFormattedValue,
            value: dragStartElement.dataset.rosterShiftsDateValue,
          },
          end: {
            formatted: dragEndElement.dataset.rosterShiftsDateFormattedValue,
            value: dragEndElement.dataset.rosterShiftsDateValue,
          },
        }

        LocalStorageService.set(LocalStorageKeysEnum.CHOSEN_ROSTER_DATE, date)

        this.preSelectChosenShifts(clientIdValue, userIdValue, dateValue)

        let title = `${date.start.formatted} - ${date.end.formatted}`
        if (date.start.value > date.end.value) {
          title = `${date.end.formatted} - ${date.start.formatted}`
        }

        ModalService.show(clientIdValue, title)
      },
      { once: true }
    )
  }

  private static shiftSelector(
    shiftId: string,
    clientId: string,
    userId: string,
    date: string,
    endSelector = ''
  ): string {
    return `[data-user-shift${endSelector}='${shiftId}-${clientId}-${userId}-${date}']`
  }
}
