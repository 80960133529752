import { Controller } from '@hotwired/stimulus'

import { getUrl } from '../helpers/url.helper'
import { AbsenceCalendarTimelineService } from '../services/absence-calendar-timeline.service'
import { DragSelectService } from '../services/drag-select.service'
import { HttpService } from '../services/http.service'
import { Logger } from '../services/logger.service'
import { ToastService } from '../services/toast.service'

export default class extends Controller<HTMLFormElement> {
  static targets = ['timelineCalendar', 'navbar']
  static values = {
    url: String,
  }
  private urlValue: string

  private timelineCalendarTarget: HTMLDivElement
  private navbarTarget: HTMLDivElement

  private datePickerElement: HTMLInputElement
  connect = async () => {
    try {
      ToastService.hide()

      this.datePickerElement = this.navbarTarget.querySelector(
        'input[name^="date_picker_form"]'
      )

      const url = getUrl(this.urlValue)
      const html = await AbsenceCalendarTimelineService.getHtml(url)

      this.timelineCalendarTarget.appendChild(html)
    } catch (error) {
      ToastService.showError(error)
      Logger.logException(error)
    }
  }

  addAbsence = (event: PointerEvent) => {
    new DragSelectService(event).createMask()

    AbsenceCalendarTimelineService.handleDrag(event)

    AbsenceCalendarTimelineService.addAbsenceCreatedListener(
      this.timelineCalendarTarget,
      this.datePickerElement,
      this.urlValue
    )
  }

  dateChanged = async () => {
    await AbsenceCalendarTimelineService.render(
      this.timelineCalendarTarget,
      this.datePickerElement,
      this.urlValue
    )
  }

  approveAbsence = async (event: PointerEvent) => {
    const target = event.target as HTMLElement
    const url = target.dataset.approvedUrl
    const id = target.dataset.id
    const approved = Number(target.dataset.approved)
    const eventElement = document
      .querySelector(`.time-line-calendar-content-grid-event[data-id="${id}"]`)
      .querySelector('div') as HTMLElement

    if (approved) {
      await HttpService.put(getUrl(url))
      eventElement.style.opacity = '1'
      target.remove()
    } else {
      await HttpService.delete(getUrl(url))
      eventElement?.remove()
      target
        .closest('.time-line-calendar-content-absence-approval-bar')
        ?.remove()
    }
  }
}
