export const resetForm = (form: HTMLFormElement): void => {
  form.reset()
  form
    .querySelectorAll('input[type="hidden"]')
    .forEach((input: HTMLInputElement) => {
      if (!input.name.includes('_token')) {
        input.value = ''
      }
    })
}
