export class SpinnerService {
  static show = (target: HTMLDivElement) => {
    target.innerHTML = ''

    const div = document.createElement('div')
    div.classList.add('spinner-grow', 'text-primary')

    const span = document.createElement('span')
    span.classList.add('visually-hidden')

    const content = document.createTextNode('Loading...')
    span.appendChild(content)

    div.appendChild(span)

    target.append(div)
  }

  static hide = (target: HTMLDivElement) => {
    target.querySelector('.spinner-grow')?.remove()
  }
}
