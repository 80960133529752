export enum LanguagesEnum {
  ARABIC = 'ar',
  ARABIC_SA = 'ar-sa',
  DUTCH = 'nl',
  ENGLISH = 'en',
  FINNISH = 'fi',
  FRENCH = 'fr',
  GERMAN = 'de',
  ITALIAN = 'it',
  POLISH = 'pl',
  ROMANIAN = 'ro',
  RUSSIAN = 'ru',
  SLOVENIAN = 'sl',
  SPANISH = 'es',
  TURKISH = 'tr',
}
