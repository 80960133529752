import { Offcanvas } from 'bootstrap'

export class OffCanvasService {
  private static instance: Offcanvas
  static show = (): HTMLElement => {
    const element = document.querySelector('.offcanvas') as HTMLElement
    if (!element) {
      return
    }
    this.instance = new Offcanvas(element)
    this.instance.show()

    return element
  }
  static hide = () => {
    this.instance.hide()
  }
}
