// Returns an array of dates between the two dates

export const getDatesBetween = (startDate: Date, endDate: Date) => {
  const dates = []

  if (startDate > endDate) {
    // Swap the values
    ;[startDate, endDate] = [endDate, startDate]
  }

  // Strip hours minutes seconds etc.
  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  )

  while (currentDate <= endDate) {
    dates.push(currentDate)

    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1 // Will increase month if over range
    )
  }

  return dates
}
