import { TempusDominus, Unit } from '@eonasdan/tempus-dominus'
import { Controller } from '@hotwired/stimulus'

import { DatePickerViewModeEnum } from '../enums/date-picker-view-mode.enum'
import { LanguagesEnum } from '../enums/languages.enum'
import { DatePickerService } from '../services/date-picker.service'
import { Logger } from '../services/logger.service'
import { ToastService } from '../services/toast.service'

/**
 * examples https://getdatepicker.com/6/repl.html
 * date formats https://getdatepicker.com/6/plugins/customDateFormat.html
 */
export default class extends Controller<HTMLFormElement> {
  static targets = ['datepicker']
  static values = {
    mode: String,
    format: String,
    language: String,
    viewDate: Number,
  }
  private instance: TempusDominus
  private datepickerTarget: HTMLInputElement
  private modeValue?: DatePickerViewModeEnum
  private formatValue?: string
  private languageValue?: LanguagesEnum
  private viewDateValue?: number

  async connect() {
    try {
      this.instance = DatePickerService.init(
        this.datepickerTarget.closest('.date-picker'),
        this.modeValue ? this.modeValue : DatePickerViewModeEnum.MONTHS,
        this.formatValue,
        this.languageValue,
        this.viewDateValue
      )
    } catch (error) {
      Logger.logException(error)
      ToastService.showError(error)
    }
  }

  next = (event: MouseEvent) => {
    event.preventDefault()
    this.instance.viewDate.manipulate(1, Unit.month)
    this.instance.dates.setValue(this.instance.viewDate)
  }

  prev = (event: MouseEvent) => {
    event.preventDefault()
    this.instance.viewDate.manipulate(-1, Unit.month)
    this.instance.dates.setValue(this.instance.viewDate)
  }
}
