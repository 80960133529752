import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'
import dayjs from 'dayjs'

import { HttpStatusCodesEnum } from '../enums/http-status-codes.enum'
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum'
import { getDatesBetween } from '../helpers/date.helper'
import { getUrl } from '../helpers/url.helper'
import { RosterSelectedDatesInterface } from '../interfaces/roster-selected-dates.interface'
import { DragSelectService } from '../services/drag-select.service'
import { HttpService } from '../services/http.service'
import { LocalStorageService } from '../services/local-storage.service'
import { LockService } from '../services/lock.service'
import { Logger } from '../services/logger.service'
import { ModalService } from '../services/modal.service'
import { RosterShiftService } from '../services/roster-shift.service'
import { ToastService } from '../services/toast.service'

let tooltip: Tooltip | undefined = undefined
let userIdValue: string
let clientIdValue: string
let lineNumberValue: string

export default class extends Controller<HTMLFormElement> {
  static values = {
    url: String,
    clientId: String,
  }
  static targets = ['absenceId']
  private urlValue: string
  private clientIdValue: string
  private absenceIdTarget: HTMLInputElement

  async connect() {
    RosterShiftService.calculateTotalShiftsPerDayForMonth(this.clientIdValue)
  }

  openToolTipHoliday(event: Event) {
    const target = event.currentTarget as HTMLDivElement
    const holidayName = target.dataset.holidayName

    tooltip = new Tooltip(target, { title: holidayName })
    tooltip.show()
  }

  showShifts(event: PointerEvent) {
    try {
      const target = event.currentTarget as HTMLDivElement

      userIdValue = target.dataset.rosterShiftsUserIdValue
      clientIdValue = target.dataset.rosterShiftsClientIdValue
      lineNumberValue = target.dataset.rosterShiftsLineNumberValue

      this.absenceIdTarget.value =
        target.dataset.rosterShiftsAbsenceTypeIdValue ?? ''

      new DragSelectService(event).createMask()

      RosterShiftService.handleDrag(event)
    } catch (error) {
      Logger.logException(error)
    }
  }

  async delete(event: MouseEvent) {
    const btnDelete = event.target as HTMLButtonElement

    try {
      if (LockService.isLocked(btnDelete)) {
        return
      }

      LockService.lock(btnDelete)

      const rosterSelectedDates = LocalStorageService.get(
        LocalStorageKeysEnum.CHOSEN_ROSTER_DATE
      ) as RosterSelectedDatesInterface

      const dates = getDatesBetween(
        new Date(rosterSelectedDates.start.value),
        new Date(rosterSelectedDates.end.value)
      )

      const promiseRequests = dates.map(async date => {
        const dateValue = dayjs(date).format('YYYY-MM-DD')
        await HttpService.delete(
          getUrl(this.urlValue, [
            { key: 'userId', value: Number(userIdValue) },
            { key: 'clientId', value: Number(clientIdValue) },
            { key: 'date', value: dateValue },
          ])
        )
        RosterShiftService.removeShiftsFromCalendar(
          clientIdValue,
          userIdValue,
          dateValue
        )
        const day: HTMLDivElement = document.querySelector(
          `[data-roster="${clientIdValue}"] [data-roster-shifts-date-total="${dateValue}"]`
        )
        RosterShiftService.calculateTotalShiftsPerDay(
          clientIdValue,
          dateValue,
          day
        )
      })
      await Promise.all(promiseRequests)

      ModalService.hide()

      RosterShiftService.resetModal(clientIdValue)

      LocalStorageService.remove(LocalStorageKeysEnum.CHOSEN_ROSTER_DATE)
    } catch (error) {
      ToastService.showError(error)
      Logger.logException(error)
    } finally {
      LockService.unlock(btnDelete)
    }
  }

  async save(event: MouseEvent) {
    const btnSave = event.target as HTMLButtonElement

    try {
      if (LockService.isLocked(btnSave)) {
        return
      }

      LockService.lock(btnSave)

      const rosterSelectedDates = LocalStorageService.get(
        LocalStorageKeysEnum.CHOSEN_ROSTER_DATE
      ) as RosterSelectedDatesInterface

      const shiftIds = RosterShiftService.availableShifts(
        clientIdValue,
        true
      ).map(({ value }) => value)

      if (shiftIds.length === 0) {
        ToastService.showMessage(
          'Bitte wählen Sie eine Schicht aus', // todo translate
          'Informationen fehlen'
        )
        return
      }

      const dates = getDatesBetween(
        new Date(rosterSelectedDates.start.value),
        new Date(rosterSelectedDates.end.value)
      )

      const promiseRequests = dates.map(async date => {
        const dateValue = dayjs(date).format('YYYY-MM-DD')

        const { html } = await HttpService.post(getUrl(this.urlValue), {
          userId: userIdValue,
          clientId: clientIdValue,
          date: dateValue,
          lineNumber: lineNumberValue,
          shiftIds,
          absenceId: this.absenceIdTarget.value,
        })

        RosterShiftService.removeShiftsFromCalendar(
          clientIdValue,
          userIdValue,
          dateValue
        )

        const calendar = document.querySelector(
          `[data-roster="${clientIdValue}"] .time-line-calendar-content .d-grid`
        )

        const content = html.trim()
        if (content.length > 1) {
          calendar.insertAdjacentHTML('beforeend', content)
        }

        const day: HTMLDivElement = document.querySelector(
          `[data-roster="${clientIdValue}"] [data-roster-shifts-date-total="${dateValue}"]`
        )

        RosterShiftService.calculateTotalShiftsPerDay(
          clientIdValue,
          dateValue,
          day
        )
      })

      await Promise.all(promiseRequests)

      ModalService.hide()

      RosterShiftService.resetModal(clientIdValue)

      this.absenceIdTarget.value = ''

      LocalStorageService.remove(LocalStorageKeysEnum.CHOSEN_ROSTER_DATE)
    } catch (error) {
      ToastService.showError(error)
      if (error.status !== HttpStatusCodesEnum.CONFLICT) {
        Logger.logException(error)
      }
    } finally {
      LockService.unlock(btnSave)
    }
  }
}
