import { AlertTypesEnum } from '../enums/alert-types.enum'
import { BaseError } from '../errors/base.error'

const toastContainer = document.querySelector('.toast-container')

export class ToastService {
  static showError = (error: BaseError) => {
    if (toastContainer) {
      toastContainer.classList.add('toast-container-danger')
      toastContainer.querySelector('.toast')?.classList.add('show')
      toastContainer.querySelector('.toast-body').textContent =
        error?.context?.message ?? error.message
      toastContainer.querySelector('strong').textContent =
        error?.context?.title ?? 'An error occurred'

      setTimeout(this.hide, 10000)
    }
  }
  static showMessage = (
    message: string,
    title: string,
    type: AlertTypesEnum = AlertTypesEnum.INFO
  ) => {
    if (toastContainer) {
      toastContainer.classList.add(`toast-container-${type}`)
      toastContainer.querySelector('.toast').classList.add('show')
      toastContainer.querySelector('.toast-body').textContent = message
      toastContainer.querySelector('strong').textContent = title

      setTimeout(this.hide, 10000)
    }
  }

  static hide = () => {
    toastContainer.classList.remove(
      'toast-container-error',
      'toast-container-info'
    )
    toastContainer?.querySelector('.toast')?.classList.remove('show')
  }
}
