import { DatePickerViewModeEnum } from '../enums/date-picker-view-mode.enum'
import { LanguagesEnum } from '../enums/languages.enum'

export const htmlLink = (link: string, text: string) => {
  return `<a href="${link}">${text}</a>`
}

export const emailLink = (email: string) => {
  if (!email) {
    return ''
  }
  return `<a href="mailto:${email}">${email}</a>`
}

export const countryFlag = (country?: string) => {
  if (!country) {
    return ''
  }
  return `<i class="fi fi-${country}"></i>`
}

export const htmlBadge = (
  text: string,
  cssClass = 'bg-dark',
  bgColor?: string
) => {
  return text
    ? `<span class="badge ${cssClass}" style="background-color: ${bgColor}">${text}</span>`
    : ''
}

export const button = (
  action: string,
  cssClass: string,
  icon?: string,
  value?: string
) => {
  return `<button class="${cssClass}" data-action="${action}" value="${value}"><i class="${icon}"></i></button>`
}

export const inputDateTime = (
  dateTime: string,
  format: string,
  mode: DatePickerViewModeEnum,
  language: LanguagesEnum
) => {
  return `<div data-controller="date-picker" data-date-picker-mode-value="${mode}" data-date-picker-format-value="${format}" class="me-md-2 mb-2" data-date-picker-language-value="${language}">
            <div class="input-group date-picker" data-td-target-input="nearest" data-td-target-toggle="nearest">
              <input type="text" readonly data-td-target="#date-picker" data-date-picker-target="datepicker" value="${dateTime}" class="form-control"/>
              <span class="input-group-text" data-td-target="#date-picker" data-td-toggle="datetimepicker">
                <i class="bi bi-calendar"></i>
              </span>
            </div>
          </div>`
}
