import { AlertTypesEnum } from '../enums/alert-types.enum'

export class AlertService {
  static show = (type: AlertTypesEnum, target: HTMLElement, text: string) => {
    if (!(target instanceof HTMLElement)) {
      return
    }

    this.remove(target)

    const p = document.createElement('p')
    p.classList.add('m-3', 'alert', 'alert-' + type)

    const content = document.createTextNode(text)
    p.appendChild(content)

    target.prepend(p)
  }

  static remove = (target?: HTMLElement) => {
    if (!(target instanceof HTMLElement)) {
      return
    }

    target.querySelectorAll('.alert').forEach(item => item.remove())
  }
}
