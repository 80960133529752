import { Controller } from '@hotwired/stimulus'

import { getUrl } from '../helpers/url.helper'
import { HttpService } from '../services/http.service'
import { Logger } from '../services/logger.service'
import { RosterService } from '../services/roster.service'
import { SpinnerService } from '../services/spinner.service'
import { ToastService } from '../services/toast.service'

export default class extends Controller<HTMLFormElement> {
  static targets = ['calendar', 'navbar', 'client', 'rosters', 'holidays']
  static values = {
    url: String,
    holidaysUrl: String,
  }
  private urlValue: string
  private holidaysUrlValue: string
  private idValue: number

  private rostersTarget: HTMLDivElement
  private navbarTarget: HTMLDivElement
  private clientTarget?: HTMLSelectElement
  private holidaysTarget: HTMLDivElement
  private hasClientTarget: boolean

  private datePickerElement: HTMLInputElement

  async connect() {
    try {
      this.datePickerElement =
        this.navbarTarget.querySelector('.date-picker input')

      if (!this.hasClientTarget) {
        await RosterService.getShifts(
          this.rostersTarget,
          this.urlValue,
          this.datePickerElement.value,
          Number(this.idValue)
        )
      }
    } catch (error) {
      Logger.logException(error)
      ToastService.showError(error)
    }
  }

  dateChanged = async () => {
    try {
      SpinnerService.show(this.rostersTarget)
      await Promise.all([this.updateHolidays(), this.clientChange()])
      SpinnerService.hide(this.rostersTarget)
    } catch (error) {
      Logger.logException(error)
      ToastService.showError(error)
    }
  }

  updateHolidays = async () => {
    this.holidaysTarget.innerHTML = ''
    this.holidaysTarget.innerHTML = await HttpService.get(
      getUrl(this.holidaysUrlValue, [
        { key: 'date', value: this.datePickerElement.value },
      ])
    )
  }

  clientChange = async () => {
    try {
      const clientIds = this.getClientIds()

      RosterService.clear(clientIds, this.rostersTarget)

      for (const id of clientIds) {
        const exists = this.rostersTarget.querySelector(`[data-roster="${id}"]`)
        if (!exists) {
          await RosterService.getShifts(
            this.rostersTarget,
            this.urlValue,
            this.datePickerElement.value,
            Number(id)
          )
        }
      }
    } catch (error) {
      Logger.logException(error)
      ToastService.showError(error)
    }
  }

  getClientIds = () => {
    if (!this.hasClientTarget) {
      return ['0']
    }
    return Array.from(this.clientTarget.selectedOptions, option => option.value)
  }
}
