import { Controller } from '@hotwired/stimulus'

import { getUrl } from '../helpers/url.helper'
import { HttpService } from '../services/http.service'
import { Logger } from '../services/logger.service'
import { ToastService } from '../services/toast.service'

export default class extends Controller<HTMLFormElement> {
  static targets = ['checkbox']
  static values = {
    url: String,
  }
  private urlValue: string

  private checkboxTarget: HTMLInputElement
  toggle = async () => {
    try {
      const active = this.checkboxTarget.checked

      const data = active
        ? await HttpService.put(getUrl(this.urlValue))
        : await HttpService.delete(getUrl(this.urlValue))

      const { message, title } = data
      ToastService.showMessage(message, title)
    } catch (error) {
      Logger.logException(error)
      ToastService.showError(error)
    }
  }
}
