import { Controller } from '@hotwired/stimulus'

import { getUrl } from '../helpers/url.helper'
import { HttpService } from '../services/http.service'
import { Logger } from '../services/logger.service'
import { OffCanvasService } from '../services/off-canvas.service'
import { ToastService } from '../services/toast.service'

export default class extends Controller<HTMLFormElement> {
  details = async (event: MouseEvent) => {
    try {
      event.preventDefault()

      const target = event.target as HTMLElement
      const btn = target.closest('button')
      let container = document.querySelector('.absence-details')

      const type = btn.dataset.type
      const id = btn.dataset.user
      const data = await HttpService.get(
        getUrl(`/absence/${type}/${id}/details/`)
      )

      if (container) {
        container.innerHTML = ''
      } else {
        container = document.createElement('div')
      }

      container.classList.add('absence-details')

      container.innerHTML = data

      document.body.append(container)

      OffCanvasService.show()
    } catch (error) {
      Logger.logException(error)
      ToastService.showError(error)
    }
  }
}
