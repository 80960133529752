export class LockService {
  private static tasks: Array<{ name: string; running: boolean }> = []

  static isLocked(btn: HTMLButtonElement) {
    const item = this.tasks.find(item => {
      return item.name === btn.name
    })

    return item?.running || false
  }

  static lock(btn: HTMLButtonElement) {
    btn.disabled = true

    const item = this.tasks.find(({ name }) => {
      return name === btn.name
    })

    if (!item) {
      this.tasks.push({ name: btn.name, running: true })
      return
    }

    this.tasks.forEach(task => {
      if (task.name === btn.name) {
        task.running = true
      }
    })
  }

  static unlock(btn: HTMLButtonElement) {
    const item = this.tasks.find(({ name }) => name === btn.name)
    if (item) {
      item.running = false
      btn.disabled = false
    }
  }
}
