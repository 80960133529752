export class LocalStorageService {
  static isSupported() {
    return typeof Storage !== 'undefined'
  }

  static has(key: string) {
    return localStorage.hasOwn(key)
  }

  static get(key: string) {
    const item = localStorage.getItem(key)

    if (typeof item !== 'string') return item

    if (item === 'undefined') return undefined

    if (item === 'null') return null

    // Check for numbers and floats
    if (/^'-?\d{1,}?\.?\d{1,}'$/.test(item)) return Number(item)

    // Check for numbers in scientific notation
    if (/^'-?\d{1}\.\d+e\+\d{2}'$/.test(item)) return Number(item)

    // Check for serialized objects and arrays
    if (item[0] === '{' || item[0] === '[') return JSON.parse(item)

    return item
  }

  static set(key: string, value: unknown) {
    if (typeof key !== 'string') {
      throw new TypeError(
        `localStorage: Key must be a string. (reading '${key}')`
      )
    }

    if (typeof value !== 'string') {
      value = JSON.stringify(value)
    }

    localStorage.setItem(key, value as string)
  }

  static remove(key: string) {
    localStorage.removeItem(key)
  }
}
