import { DragEndEvent } from '../events/drag-end.event'
import { DragendDetailInterface } from '../interfaces/drag-end-event.interface'
import { SelectableInterface } from '../interfaces/selectable.interface'
import { Logger } from './logger.service'
import { ToastService } from './toast.service'

export class DragSelectService {
  private selector = '.time-line-calendar-content-day'
  private readonly x: number
  private readonly y: number
  private selectedMask: HTMLDivElement
  private selectedMaskHeight = '30px'
  private calendar: Element
  private diffY: number
  private readonly dragStartElement: HTMLElement

  constructor(event: PointerEvent) {
    event.preventDefault()

    this.x = event.clientX
    this.y = event.clientY

    this.dragStartElement = event.currentTarget as HTMLElement

    this.calendar = this.dragStartElement.closest('.time-line-calendar-content')
  }

  public createMask = () => {
    this.calendar.classList.add('mouse_down')

    this.diffY = this.dragStartElement.offsetTop + 10

    this.selectedMask = document.createElement('div')
    this.selectedMask.style.position = 'absolute'
    this.selectedMask.style.width = '0'
    this.selectedMask.style.height = this.selectedMaskHeight
    this.selectedMask.style.left = `${this.x}px`
    this.selectedMask.style.top = `${this.y}px`
    this.selectedMask.classList.add('drag-select')

    this.calendar.append(this.selectedMask)

    this.calendar.addEventListener('pointermove', this.dragStart)

    this.calendar.addEventListener('pointerup', this.dragEnd, {
      once: true,
    })

    this.selectables().forEach(item =>
      item.elem.classList.remove('intersected')
    )
  }

  private selectables = () => {
    const selectables: SelectableInterface[] = []
    const selectableElems = [...this.calendar.querySelectorAll(this.selector)]

    for (const selectable of selectableElems) {
      const { x, y, width, height } = selectable.getBoundingClientRect()
      selectables.push({
        x: x + window.scrollX,
        y: y + window.scrollY,
        width,
        height,
        elem: selectable,
      })
    }

    return selectables
  }

  private checkSelected = () => {
    const { x, y, height, width } = this.selectedMask.getBoundingClientRect()

    for (const selectable of this.selectables()) {
      const isValidSelection = this.checkRectIntersection(
        {
          x: x + window.scrollX,
          y: y + window.scrollY,
          height,
          width,
        },
        selectable
      )

      if (isValidSelection) {
        selectable.elem.classList.add('intersected')
      } else {
        selectable.elem.classList.remove('intersected')
      }
    }
  }

  private checkRectIntersection = (
    r1: SelectableInterface,
    r2: SelectableInterface
  ) => {
    return !(
      r1.x + r1.width < r2.x ||
      r2.x + r2.width < r1.x ||
      r1.y + r1.height < r2.y ||
      r2.y + r2.height < r1.y
    )
  }

  private dragStart = (event: PointerEvent) => {
    const diffX = event.pageX - this.x

    this.selectedMask.style.left =
      diffX < 0 ? `${this.x + diffX}px` : `${this.x}px`
    this.selectedMask.style.top = `${this.diffY}px`
    this.selectedMask.style.width = `${Math.abs(diffX)}px`

    this.checkSelected()
  }

  private dragEnd = (event: PointerEvent) => {
    try {
      this.calendar.classList.remove('mouse_down')
      this.calendar.removeEventListener('pointermove', this.dragStart)

      this.selectedMask.remove()
      const x = event.clientX
      const y = event.clientY

      const dragEndElement = document.elementFromPoint(x, y) as HTMLElement

      const eventDetails: DragendDetailInterface = {
        dragStartElement: this.dragStartElement,
        dragEndElement,
      }

      this.dragStartElement.dispatchEvent(new DragEndEvent(eventDetails))
    } catch (error) {
      Logger.logException(error)
      // todo translate
      ToastService.showMessage(
        'Es ist ein unerwarteter Fehler aufgetreten, wir kümmern uns bereits darum',
        'Es ist ein Fehler aufgetreten'
      )
    }
  }
}
