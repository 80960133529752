import { Modal } from 'bootstrap'

import { AlertTypesEnum } from '../enums/alert-types.enum'
import { AlertService } from './alert.service'

export class ModalService {
  private static modalContainer: HTMLDivElement
  private static modal: Modal

  static show = (clientIdValue: string, title: string) => {
    this.modalContainer = this.getHtmlElement(clientIdValue)

    if (!this.modalContainer) {
      return
    }

    this.modalContainer.querySelector('.modal-title strong').innerHTML = title

    this.modalContainer.addEventListener(
      'hidden.bs.modal',
      this.addListenerToRemoveError,
      { once: true }
    )

    this.modal = new Modal(this.modalContainer, {
      focus: true,
    })

    this.modal.show()
  }

  static addListenerToRemoveError = () => {
    AlertService.remove(this.modalContainer)
  }

  static error = (message: string) => {
    AlertService.show(
      AlertTypesEnum.DANGER,
      this.modalContainer.querySelector('.modal-body'),
      message
    )
  }

  static hide = () => {
    this.modal.hide()
    AlertService.remove(this.modalContainer)
  }

  static getHtmlElement = (clientIdValue: string) => {
    this.modalContainer = document.getElementById(
      `shiftModal-${clientIdValue}`
    ) as HTMLDivElement

    return this.modalContainer
  }
}
