import { DragendDetailInterface } from '../interfaces/drag-end-event.interface'

export class DragEndEvent extends CustomEvent<DragendDetailInterface> {
  constructor(detail: DragendDetailInterface) {
    super('dragEndEvent', {
      detail,
      bubbles: true,
      cancelable: true,
      composed: false,
    })
  }
}
