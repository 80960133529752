import { Controller } from '@hotwired/stimulus'

const toggle = document.querySelector<HTMLElement>('.header_toggle'),
  aside = document.querySelector<HTMLElement>('aside'),
  body = document.querySelector<HTMLElement>('body'),
  header = document.querySelector<HTMLElement>('header')

export default class extends Controller<HTMLFormElement> {
  connect() {
    this.showSideBar()
  }

  showSideBar() {
    if (toggle && aside && body && header) {
      toggle.addEventListener('click', this.toggleClasses, { once: false })
    }
  }

  toggleClasses() {
    // show navbar
    aside?.classList.toggle('aside_collapse')
    // add padding to body
    body?.classList.toggle('aside_collapse-padding')
    // add padding to header
    header?.classList.toggle('aside_collapse-padding')
  }
}
