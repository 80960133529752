import { ErrorSeverityEnum } from '../enums/error-severity.enum'
import { BaseError } from './base.error'

export class ConfigLoadingError extends BaseError {
  constructor(message: string) {
    super(new Error(message))
    this.severity = ErrorSeverityEnum.Info
    this.name = 'ConfigLoadingError'
  }
}
