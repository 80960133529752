import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

import { BaseError } from '../errors/base.error'
import { UserListLoadError } from '../errors/user-list-load.error'
import { getUrl } from '../helpers/url.helper'
import { TableContentInterface } from '../interfaces/table-content.interface'
import { HttpService } from '../services/http.service'
import { Logger } from '../services/logger.service'
import { PaginationService } from '../services/pagination.service'
import { TableService } from '../services/table.service'
import { ToastService } from '../services/toast.service'

export abstract class TableContentController extends Controller<HTMLFormElement> {
  static targets = ['table', 'pagination', 'total', 'active', 'search']
  static values = {
    url: String,
  }
  protected tableTarget: HTMLTableElement
  protected totalTarget: HTMLElement
  protected paginationTarget: HTMLUListElement
  protected activeTarget: HTMLInputElement
  protected searchTarget: HTMLInputElement
  protected itemsPerPage = 10
  protected currentPage = 1
  protected urlValue: string

  async connect() {
    await this.load()
  }

  initialize() {
    this.search = debounce(this.search, 500).bind(this)
  }

  search = async () => {
    this.currentPage = 1
    await this.load()
  }

  load = async () => {
    try {
      const { total, items } = await HttpService.post(getUrl(this.urlValue), {
        page: this.currentPage,
        limit: this.itemsPerPage,
        active: this.activeTarget.checked,
        search: this.searchTarget.value,
      })

      this.totalTarget.textContent = String(total)
      const rows = this.getRows(items)

      TableService.removeAllRows(this.tableTarget)
      TableService.addRows(this.tableTarget, rows)
      PaginationService.render({
        event: this.changePage,
        total,
        paginationTarget: this.paginationTarget,
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      })
    } catch (error) {
      let err = error
      if (!(error instanceof BaseError)) {
        err = new UserListLoadError(error)
      }
      ToastService.showError(err)
      Logger.logException(err)
    }
  }

  changePage = async (event: MouseEvent) => {
    event.preventDefault()
    const target = event.currentTarget as HTMLUListElement
    this.currentPage = Number(target.getAttribute('data-page'))
    await this.load()
  }

  abstract getRows(data: object[]): TableContentInterface[]
}
