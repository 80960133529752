export const htmlLink = (link: string, text: string) => {
  return `<a href="${link}">${text}</a>`
}

export const phoneLink = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return null
  }
  const formattedPhoneNumber = phoneNumber.replace(/\s/g, '')
  return `<a href="tel:${formattedPhoneNumber}">${phoneNumber}</a>`
}
